<template>
  <b-card title="Data Materi ">
    <b-row>
      <b-table
          stacked
          outlined
          :items="items"
          :fields="fields"
          width="100px"
          text-align="left"
        >
        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(isimateri)>
          <div v-if="items[0].link_video!=null">
            <youtube
            player-width="520"
            player-height="295"
            v-for="item in items"
            :key="item.id"
            :video-id="item.link_video"
            />
          </div>
          <a
          v-else-if="items[0].file_pdf!=null"
          :href="items[0].file_pdf" target="_blank">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="success"
              size="sm"
            >
              <span class="align-middle">{{ items[0].nama_materi }}</span>
            </b-button>
          </a>
          <a v-else>Tidak ada materi</a>
        </template>
        <template #cell(penjelasan)="data">
          <span class="col-12 mb-1" v-html="filterLatex(data.item.penjelasan)" />
        </template>
      </b-table>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BRow, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import VueYouTubeEmbed, { YouTubePlayer } from 'vue-youtube-embed'
import katex from 'katex'
import 'katex/dist/katex.min.css'

Vue.use(VueYouTubeEmbed, { global: false })

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BBadge,
    youtube: YouTubePlayer,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'Id',
        },
        { key: 'nama_materi', label: 'Nama Materi' },
        {
          key: 'isimateri',
          label: 'Isi Materi',
          sortable: true,
        },
        { key: 'penjelasan', label: 'Penjelasan' },
        { key: 'status', label: 'Status' },
      ],
      items: [
      ],
      status: [{
        1: 'Aktif', 0: 'Tidak Aktif',
      },
      {
        1: 'light-success', 0: 'light-danger',
      }],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.getData()
  },
  created() {
    this.getData()
  },
  methods: {
    filterLatex(text) {
      const regex = /\*mtk(.*?)mtk\*/
      const match = text.match(regex)
      if (match && match.length > 1) {
        const latexText = match[1].trim()
        const renderedLatexText = this.renderLatex(latexText)
        return text.replace(/\*mtk(.*?)mtk\*/, `<span style="color: blue;"></span>${renderedLatexText}`)
      }
      return text.replace(/\[(.*?)\]/g, '<span style="color: blue;">$1</span>')
    },
    escapeLatexCharacters(latexString) {
      return latexString
        .replace(/\\/g, '\\\\')
        .replace(/\$/g, '\\$')
        .replace(/#/g, '\\#')
        .replace(/_/g, '\\_')
        .replace(/\^/g, '\\^{}')
        .replace(/{/g, '\\{')
        .replace(/}/g, '\\}')
    },
    renderLatex(latexString) {
      try {
        return katex.renderToString(latexString)
      } catch (error) {
        return 'Error: Invalid LaTeX syntax (Rumus tidak terbaca, Harap gunakan foto )'
      }
    },
    getData() {
      this.$http.get(`/bab-materi/${this.$route.params.idiBab}/materi/${this.$route.params.idMateri}`).then(response => {
        if (response.data.status) {
          this.items = response.data.data
        }
      })
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/bab/${this.$route.params.idKelas}/${this.$route.params.idMapel}/${id}`).then(response => {
            if (response.data.status) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getData()
            } else {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Membatalkan untuk menghapus data!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}

</script>

<style>
.align-label{
  width: 30%;
}
</style>
